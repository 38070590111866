import _map from 'lodash/map'
import subYears from 'date-fns/subYears'

// FEATURES
export const FEATURES = {
  AUTHORIZATION_CODE_AUTH: 'AUTHORIZATION_CODE_AUTH',
  AUTO_REWARDS: 'AUTO_REWARDS',
  AUTO_REWARDS_REGISTRATION: 'AUTO_REWARDS_REGISTRATION',
  DISCLAIMER: 'DISCLAIMER',
  EDUCATION: 'EDUCATION',
  FTE: 'FTE',
  GUARDIANSHIP: 'GUARDIANSHIP',
  HOME: 'HOME',
  HRA: 'HRA',
  HRA_RISK: 'HRA_RISK',
  HRA_SAT: 'HRA_SAT',
  LEARN_MORE: 'LEARN_MORE',
  MOCK_CAHPS: 'MOCK_CAHPS',
  NO_MOBILE_APP: 'NO_MOBILE_APP',
  NON_DISCRIMINATION: 'NON_DISCRIMINATION',
  PRESCRIPTION_RECORD: 'PRESCRIPTION_RECORD',
  REWARDS: 'REWARDS',
  REWARDS_EXTERNAL_REDEMPTION: 'REWARDS_EXTERNAL_REDEMPTION',
  REWARD_BOXES: 'REWARD_BOXES',
  SMS_OPT_IN: 'SMS_OPT_IN',
  TRACKING: 'TRACKING',
  TRACKING_ON_HOME_SCREEN: 'TRACKING_ON_HOME_SCREEN',
  LEGACY_ACCOUNT_PAGE: 'LEGACY_ACCOUNT_PAGE',
  SUPPRESS_MEMBER_EDIT_ADDRESS: 'SUPPRESS_MEMBER_EDIT_ADDRESS',
}

export const CURRENCY_TYPES = {
  DOLLARS: 'dollars',
  POINTS: 'points',
}

export const MESSAGE_TYPES = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
}

//TIMINGS
export const FLASH_MESSAGE_TIMEOUT = 3000
export const TOKEN_REFRESH_DIFFERENCE = 10
export const NATIVE_TOKEN_REFRESH_IN_MINUTES = 30

export const TABS = {
  HOME: 'Home',
  REWARDS: 'Rewards',
  MORE: 'More',
  LEARN: 'Learn',
  INBOX: 'Inbox',
}

//Questionnaire
export const QUESTIONNAIRE_TIMING = {
  START_UP: 'START_UP',
  ON_DEMAND: 'ON_DEMAND',
}
export const QUESTIONNAIRE_TYPES = {
  FTE: 'FTE',
  HRA: 'HRA',
  HRA_SAT: 'HRA_SAT',
  MOCK_CAHPS: 'MOCK_CAHPS',
  MROS_DR_APPT: 'MROS_DR_APPT',
  MROS_MENTAL_HEALTH: 'MROS_MENTAL_HEALTH',
  MROS_PHYSICAL_HEALTH: 'MROS_PHYSICAL_HEALTH',
  MEDICAID_CHILD_MOCK_CAHPS: 'MEDICAID_CHILD_MOCK_CAHPS',
  MEDICAID_ADULT_MOCK_CAHPS: 'MEDICAID_ADULT_MOCK_CAHPS',
  PROVIDER: 'PROVIDER',
  SNS: 'SNS',
  FMC: 'FMC',
  PATIENT_ENGAGEMENT: 'PATIENT_ENGAGEMENT',
}
export const QUESTIONNAIRE_SCREEN_TYPES = {
  EXIT: 'EXIT',
  GROUP: 'GROUP',
  PROCESS: 'PROCESS',
  START: 'START',
  SUCCESS: 'SUCCESS',
  WELCOME_BACK: 'WELCOME_BACK',
}
export const QUESTIONNAIRE_STATUS = {
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  NOT_STARTED: 'NOT_STARTED',
  REFUSED: 'REFUSED',
}
export const QUESTION_TYPES = {
  TEXTBOX: 'TEXTBOX',
  TEXTAREA: 'TEXTAREA',
  RADIO_BUTTONS: 'RADIO_BUTTONS',
  CHECKBOXES: 'CHECKBOXES',
  DROPDOWN: 'DROPDOWN',
  LINEAR_SCALE: 'LINEAR_SCALE',
  DATE_FIELD: 'DATE_FIELD',
}

// Trackers
export const TRACKER_TYPES = {
  nutrition: 'NUTRITION',
  weight: 'WEIGHT',
  workouts: 'WORKOUTS',
  glucose: 'GLUCOSE',
  steps: 'STEPS',
  bloodPressure: 'BLOOD_PRESSURE',
  sleep: 'SLEEP',
}

//APP SETTINGS
export const VIEWED_SPLASH_INTRO = 'viewedSplashIntro'
export const NATIVE_HEALTH_DATA_INIT_AUTH_COMPLETE =
  'nativeHealthDataInitAuthComplete'

//MESSAGE STATUSES
export const MESSAGE_STATUS = {
  READ: 'READ',
  UNREAD: 'UNREAD',
  ARCHIVE: 'DELETE',
}

//MISC
export const MINIMUM_AGE = 18
export const MAX_MINOR_BIRTH_DATE = subYears(new Date(), MINIMUM_AGE)

export const GENDER = {
  M: 'Male',
  F: 'Female',
}

export const NULL_COVERAGE_END_DATE = '2099-01-01'

export const STATES = [
  { display: 'Alabama', value: 'AL' },
  { display: 'Alaska', value: 'AK' },
  { display: 'Arizona', value: 'AZ' },
  { display: 'Arkansas', value: 'AR' },
  { display: 'California', value: 'CA' },
  { display: 'Colorado', value: 'CO' },
  { display: 'Connecticut', value: 'CT' },
  { display: 'Delaware', value: 'DE' },
  { display: 'District of Columbia', value: 'DC' },
  { display: 'Florida', value: 'FL' },
  { display: 'Georgia', value: 'GA' },
  { display: 'Hawaii', value: 'HI' },
  { display: 'Idaho', value: 'ID' },
  { display: 'Illinois', value: 'IL' },
  { display: 'Indiana', value: 'IN' },
  { display: 'Iowa', value: 'IA' },
  { display: 'Kansas', value: 'KS' },
  { display: 'Kentucky', value: 'KY' },
  { display: 'Louisiana', value: 'LA' },
  { display: 'Maine', value: 'ME' },
  { display: 'Maryland', value: 'MD' },
  { display: 'Massachusetts', value: 'MA' },
  { display: 'Michigan', value: 'MI' },
  { display: 'Minnesota', value: 'MN' },
  { display: 'Mississippi', value: 'MS' },
  { display: 'Missouri', value: 'MO' },
  { display: 'Montana', value: 'MT' },
  { display: 'Nebraska', value: 'NE' },
  { display: 'Nevada', value: 'NV' },
  { display: 'New Hampshire', value: 'NH' },
  { display: 'New Jersey', value: 'NJ' },
  { display: 'New Mexico', value: 'NM' },
  { display: 'New York', value: 'NY' },
  { display: 'North Carolina', value: 'NC' },
  { display: 'North Dakota', value: 'ND' },
  { display: 'Ohio', value: 'OH' },
  { display: 'Oklahoma', value: 'OK' },
  { display: 'Oregon', value: 'OR' },
  { display: 'Pennsylvania', value: 'PA' },
  { display: 'Rhode Island', value: 'RI' },
  { display: 'South Carolina', value: 'SC' },
  { display: 'South Dakota', value: 'SD' },
  { display: 'Tennessee', value: 'TN' },
  { display: 'Texas', value: 'TX' },
  { display: 'Utah', value: 'UT' },
  { display: 'Vermont', value: 'VT' },
  { display: 'Virginia', value: 'VA' },
  { display: 'Washington', value: 'WA' },
  { display: 'West Virginia', value: 'WV' },
  { display: 'Wisconsin', value: 'WI' },
  { display: 'Wyoming', value: 'WY' },
]

export const SHOULD_LOAD_THRESHOLD = 60

export const INVALID_CREDENTIALS = 'Invalid credentials'
export const INVALID_CREDENTIALS_MSG = 'Email or Password Incorrect'

export const HEALTH_ACTION_CODES = {
  COMPLETE_HEALTH_QUESTIONNAIRE: 'COMPLETE_HEALTH_QUESTIONNAIRE',
  HEALTH_PLAN_FEEDBACK_SURVEY: 'HEALTH_PLAN_FEEDBACK_SURVEY',
  EDUCATION_BUNDLE: 'EDUCATION_BUNDLE',
  MEMBER_RECORDED_OUTCOMES_SURVEY: 'MEMBER_RECORDED_OUTCOMES_SURVEY',
  MCD_ADULT_HEALTH_PLAN_FEEDBACK_SURVEY:
    'MCD_ADULT_HEALTH_PLAN_FEEDBACK_SURVEY',
  MCD_YOUTH_HEALTH_PLAN_FEEDBACK_SURVEY:
    'MCD_YOUTH_HEALTH_PLAN_FEEDBACK_SURVEY',
  PROFILE_UPDATE: 'PROFILE_UPDATE',
  PROVIDER_SURVEY: 'PROVIDER_SURVEY',
  SNS_SURVEY: 'SNS_SURVEY',
  FMC: 'FMC',
  PATIENT_ENGAGEMENT_SURVEY: 'PATIENT_ENGAGEMENT_SURVEY',
}

export const HEALTH_ACTION_GROUP_IDS = {
  ACCOUNT_ACTIVITY: 'ACCOUNT_ACTIVITY',
  CHRONIC: 'CHRONIC',
  EDUCATION: 'EDUCATION',
  FITNESS: 'FITNESS',
  HEALTH_OUTCOMES: 'HEALTH_OUTCOMES',
  LEARN_AND_EARN: 'LEARN_AND_EARN',
  PREVENTIVE: 'PREVENTIVE',
}

export const HEALTH_ACTION_TYPES = {
  SIMPLE: 'S',
  COMPLEX: 'X',
  COMPOUND: 'C',
}

export const MEMBER_ACTIVITY_SUBMISSION_TYPES = {
  READ_ARTICLE: 'READ_ARTICLE',
  PROFILE_UPDATE: 'PROFILE_UPDATE',
}

export const AGREEMENT_TYPES = {
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  GINA: 'GINA',
  PRIVACY_AGREEMENT: 'PRIVACY_AGREEMENT',
  SWEEPSTAKE_OFFICIAL_RULES: 'SWEEPSTAKE_OFFICIAL_RULES',
  TERMS_OF_USE: 'TERMS_OF_USE',
  TUTORIAL_WEB: 'TUTORIAL_WEB',
  TUTORIAL_MOBILE: 'TUTORIAL_MOBILE',
  PHA_WELCOME: 'PHA_WELCOME',
}

export const AGREEMENT_TAG_TYPES = {
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_OF_USE: 'TERMS_OF_USE',
  PHA_WELCOME: 'PHA_WELCOME',
}

export const AGREEMENT_RECURRENCE_TYPES = {
  ONCE: 'ONCE',
  ANNUAL_CALENDAR: 'ANNUAL_CALENDAR',
  ANNUAL_WINDOW: 'ANNUAL_WINDOW',
}

export const ACTIVITY_TYPES = {
  SSO_MEMBER_REGISTRATION_INFO: 'SSO_MEMBER_REGISTRATION_INFO',
  PROFILE_UPDATED: 'PROFILE_UPDATED',
  READ_ARTICLE: 'READ_ARTICLE',
  PLAYED_BRAIN_EXERCISE: 'PLAYED_BRAIN_EXERCISE',
}

export const APP_SUPPORTED_LOCALES = [
  { language: 'EN' },
  { language: 'ES' },
  { language: 'AR' },
  { language: 'FA' },
  { language: 'KM' },
  { language: 'KO' },
  { language: 'RU' },
  { language: 'TL' },
  { language: 'VI' },
  { language: 'ZH' },
]

export const APP_SUPPORTED_LANGUAGES = _map(APP_SUPPORTED_LOCALES, 'language')

export const HEALTH_KIT = 'healthkit'
export const GOOGLE_FIT = 'google_fit'

// these should match the LoginType from HealthmineCommon
export const TOKEN_LOGIN_TYPES = {
  SSO_SAML_SP_INIT: 'SSO_SAML_SP_INIT',
  SSO_SAML_IDP_INIT: 'SSO_SAML_IDP_INIT',
  SSO_OIDC: 'SSO_OIDC',
}

export const COMMUNICATION_METHOD_CODES = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  CALL: 'CALL',
  MAIL: 'MAIL',
}

export const MFA_TYPES = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
}

export const COMMUNICATION_METHODS = [
  {
    code: COMMUNICATION_METHOD_CODES.EMAIL,
    icon: 'paper-plane',
    title: 'notification_settings.email',
    body: 'notification_settings.email_body',
    requiredFeatures: [],
    isToggle: true,
  },
  {
    code: COMMUNICATION_METHOD_CODES.SMS,
    icon: 'comment-alt',
    title: 'notification_settings.text_messages',
    body: 'notification_settings.text_messages_body',
    requiredFeatures: [FEATURES.SMS_OPT_IN],
    isToggle: true,
  },
  {
    code: COMMUNICATION_METHOD_CODES.CALL,
    icon: 'phone-alt',
    title: 'notification_settings.phone',
    body: 'notification_settings.phone_body',
    requiredFeatures: [],
    isToggle: false,
  },
  {
    code: COMMUNICATION_METHOD_CODES.MAIL,
    icon: 'envelope',
    title: 'notification_settings.mail',
    body: 'notification_settings.mail_body',
    requiredFeatures: [],
    isToggle: false,
  },
]

export const EMAIL_VERIFICATION_TYPES = {
  VERIFY_EMAIL_ADDRESS: 'VERIFY_EMAIL_ADDRESS',
  VERIFY_ENROLLMENT_EMAIL_ADDRESS: 'VERIFY_ENROLLMENT_EMAIL_ADDRESS',
}

export const HEALTH_ACTION_CATALOG_ICON_CLASSES = {
  GENERAL: { web: 'fas fa-clipboard-list', mobile: 'clipboard-list' },
  DIGITAL: { web: 'fas fa-laptop-medical', mobile: 'laptop-medical' },
  HEALTH: { web: 'fas fa-hand-holding-heart', mobile: 'hand-holding-heart' },
}
