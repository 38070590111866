import types from './client.types'
import _get from 'lodash/get'
import { CURRENCY_TYPES } from '@platform-shared/constants'

export default {
  [types.GET_CONFIG_REQUEST]: (state) => {
    state.meta.isLoading = true
  },
  [types.GET_CONFIG_SUCCESS]: (state, config) => {
    state.meta.loadedOn = new Date()
    state.meta.isLoading = false
    state.meta.error = false
    state.features = _get(config, 'features', [])
    state.support = _get(config, 'support', {})
    state.contracts = _get(config, 'contracts', [])
    state.insuranceCards = _get(config, 'insuranceCards', {})
    state.lingo = _get(config, 'lingo', {})
    state.termPeriodDayCount = _get(config, 'termPeriodDayCount', 0)
    state.loginMessage = _get(config, 'loginMessage', {})
    state.importantDocuments = _get(config, 'importantDocuments', [])
    state.languages = _get(config, 'languages', [])
    state.defaultMedicalPlanTypeCode = _get(
      config,
      'defaultMedicalPlanTypeCode',
      'UNK'
    )
    state.lineOfBusinessTypeCode = _get(config, 'lineOfBusinessTypeCode', 'UNK')
    state.currency = _get(
      CURRENCY_TYPES,
      config.currency,
      CURRENCY_TYPES.DOLLARS
    ).toLowerCase()
    state.races = _get(config, 'races', [])
    state.ethnicities = _get(config, 'ethnicities', [])
    state.preferredLanguages = _get(config, 'preferredLanguages', [])
  },
  [types.GET_CONFIG_FAILURE]: (state) => {
    state.meta.isLoading = false
    state.meta.error = true
  },
}
