import clientConfig from '@platform-shared/utils/clientConfig'
import { isMobile, isWeb } from '@platform-shared/utils/device'
import _includes from 'lodash/includes'
import _get from 'lodash/get'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import includes from 'lodash/fp/includes'
import isPlainObject from 'lodash/fp/isPlainObject'
import find from 'lodash/fp/find'
import getOr from 'lodash/fp/getOr'
import map from 'lodash/fp/map'
import some from 'lodash/fp/some'
import { APP_SUPPORTED_LANGUAGES } from '../../constants'
import sortBy from 'lodash/fp/sortBy'

export default {
  hasLoaded: (state) => !!state.meta.loadedOn,
  loading: (state) => state.meta.isLoading,
  features: (state) => state.features,
  defaultMedicalPlanTypeCode: (state) =>
    state.defaultMedicalPlanTypeCode || 'UNK',
  lineOfBusinessTypeCode: (state) => state.lineOfBusinessTypeCode || 'UNK',
  support: (state) => state.support,
  supportByLanguageCd: (state) => (languageCd) =>
    _get(state.support, languageCd, state.support.EN),
  contracts: (state) => state.contracts,
  partitionContracts: (state, getters) => {
    const s3Partition = _get(clientConfig, 'S3_PARTITION', '')
    const baseContracts = getters.contracts
    const isPartitionedContracts = isPlainObject(baseContracts)

    return isPartitionedContracts
      ? getOr([], s3Partition, baseContracts)
      : baseContracts
  },
  loginLogos: (state, getters) => {
    const contracts = getters.partitionContracts

    const usePrimaryLogoAsFallback = !some('logo')(contracts)

    const contractLogos = flow(
      filter(
        (contract) =>
          !!_get(contract, 'logo') &&
          ((isMobile && !_get(contract, 'excludeLogoOnMobileLogin')) ||
            (isWeb && !_get(contract, 'excludeLogoOnWebLogin')))
      ),
      map((contract) => _get(contract, 'logo'))
    )(contracts)

    return !contractLogos.length && usePrimaryLogoAsFallback
      ? ['logo_primary.png']
      : contractLogos
  },
  contractLogo: (state, getters) => (contractCode) =>
    flow(
      find(flow(getOr([], 'codes'), includes(contractCode))),
      getOr('logo_primary.png', 'logo')
    )(getters.partitionContracts),
  memberCards: (state) => {
    const s3Partition = _get(clientConfig, 'S3_PARTITION', '')
    const cards = getOr([], s3Partition, state.insuranceCards)
    return cards.length > 0 ? cards : [{ file: 'insurance_card.png' }]
  },
  clientName: () => _get(clientConfig, 'NAME'),
  lingo: (state) => state.lingo,
  supportLingo: (state) => state.lingo.support,
  termPeriodDayCount: (state) => {
    return _get(state, 'termPeriodDayCount', 0)
  },
  importantDocuments: (state) => state.importantDocuments,
  hasFeature: (state, getters) => (feature) =>
    _includes(getters.features, feature),
  footerDisclaimer: (state, getters) => {
    const s3Partition = _get(clientConfig, 'S3_PARTITION', '')
    const baseFooterDisclaimer = _get(getters.lingo, 'footerDisclaimer')
    const isPartitionedDisclaimer = isPlainObject(baseFooterDisclaimer)

    return (
      (isPartitionedDisclaimer
        ? _get(baseFooterDisclaimer, s3Partition)
        : baseFooterDisclaimer) || ''
    )
  },

  // include only languges that have client side translation
  languages: (state) =>
    flow(
      filter(({ code }) => _includes(APP_SUPPORTED_LANGUAGES, code)),
      map(({ code, name }) => ({
        code,
        name,
      })),
      // Sorting step to ensure 'en' is at the top
      sortBy((language) => (language.code === 'en' ? '0' : language.code))
    )(state.languages),

  showLanguage: (state, getters) => getters.languages.length > 1,

  ethnographicInfo: (state) => {
    return {
      races: state.races.map((el) => {
        return { display: el.name, value: el.code, ...el }
      }),
      ethnicities: state.ethnicities.map((el) => {
        return { display: el.name, value: el.code, ...el }
      }),
      preferredLanguages: state.preferredLanguages.map((el) => {
        return { display: el.name, value: el.code, ...el }
      }),
    }
  },

  loginMessage: (state) => (languageCd) =>
    _get(state.loginMessage, languageCd, state.loginMessage.EN),

  cohortIncentiveStrategyType: (state, getters) =>
    getters.hasFeature('COHORT_INCENTIVE_STRATEGY')
      ? 'COHORT'
      : 'EMPLOYER_GROUP',
}
